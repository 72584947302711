"use client";
import { useActionState, useEffect, useRef, useState } from "react";
import { signin } from "@/app/actions/authActions";
import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { initialStore, store } from "../lib/store";

function LoginPage() {
	const [state, action, pending] = useActionState(signin, undefined);
	const formRef = useRef<HTMLFormElement>(null);
	const [passwordToggle, setPasswordToggle] = useState(false);
	console.log(state);
	// useEffect(() => {
	// 	console.log("pending", pending);
	// 	console.log("state", state);
	// 	if (state && "serviceWorker" in navigator) {
	// 		navigator.serviceWorker.ready.then((reg) => {
	// 			reg.active?.postMessage({
	// 				type: "action",
	// 				action: "clearCache",
	// 				// item: "",
	// 			});
	// 		});
	// 	}
	// }, [pending, state]);

	useEffect(() => {
		console.log("state", store.state);
		store.setState((state) => {
			return { ...state, ...initialStore };
		});
		if ("serviceWorker" in navigator) {
			navigator.serviceWorker.ready.then((reg) => {
				reg.active?.postMessage({
					type: "action",
					action: "clearCache",
					// item: "",
				});
			});
		}
	}, [state]);

	return (
		<div className="relative bg-[url('/bg.jpg')] bg-cover bg-[50%] bg-no-repeat text-slate-900 dark:text-slate-100 h-dvh overflow-x-hidden flex flex-col grow overflow-y-auto scroll-smooth">
			{/* bg-slate-200 dark:bg-slate-800 */}
			<main className="w-full flex flex-col grow justify-center sm:container sm:mx-auto">
				<form
					action={action}
					className="bg-slate-300/75 dark:bg-slate-700/75 rounded-2xl grid grid-cols-3 mx-2 sm:mx-auto sm:w-full sm:max-w-1/2 lg:max-w-1/3 gap-2 px-4 py-2"
					ref={formRef}
				>
					<div className="col-start-1 col-span-3 w-full">
						<h1 className="text-3xl text-center">
							Henchman<b>TRAK</b> Portal
						</h1>
					</div>

					<div className="col-start-1 col-span-3">
						<label htmlFor="username">Username</label>
						<input
							id="username"
							name="username"
							type="text"
							placeholder="Username"
							className={`block w-full border disabled:cursor-not-allowed disabled:opacity-50 border-gray-300 bg-gray-50 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-cyan-500 dark:focus:ring-cyan-500 p-2.5 text-sm rounded-lg focus-visible:outline-none ${
								state?.errors?.username
									? "border-red-400 dark:border-red-700"
									: ""
							}`}
						/>
					</div>
					{state?.errors?.username && (
						<p className="col-start-1 col-span-3 text-slate-600 dark:text-slate-300">
							{state.errors.username}
						</p>
					)}
					<div className="col-start-1 col-span-3">
						<label htmlFor="password">Password</label>
						<div className="relative">
							<input
								id="password"
								name="password"
								type={passwordToggle ? "text" : "password"}
								placeholder="Password"
								className={`relative left-0 block w-full border disabled:cursor-not-allowed disabled:opacity-50 border-gray-300 bg-gray-50 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-cyan-500 dark:focus:ring-cyan-500 p-2.5 text-sm rounded-lg focus-visible:outline-none ${
									state?.errors?.username
										? "border-red-400 dark:border-red-700"
										: ""
								}`}
							/>
							<button
								className="absolute right-1.5 inset-y-0 min-w-5"
								type="button"
								onClick={(e) => {
									e.preventDefault();
									setPasswordToggle(!passwordToggle);
								}}
							>
								{passwordToggle ? (
									<FontAwesomeIcon icon={faEye} className="" />
								) : (
									<FontAwesomeIcon icon={faEyeSlash} />
								)}
							</button>
						</div>
					</div>
					{state?.errors?.password && (
						<div className="col-start-1 col-span-3 text-slate-600 dark:text-slate-300">
							<p>Password must:</p>
							<ul className="pl-5">
								{state.errors.password.map((error) => (
									<li key={error}>- {error}</li>
								))}
							</ul>
						</div>
					)}
					{state?.message && (
						<p className="col-start-1 col-span-3 drop-shadow-sm text-red-600 dark:text-red-400">
							{state.message}
						</p>
					)}
					<div className="col-start-1 col-span-3 flex">
						<Link
							href="/signup"
							prefetch={true}
							className=" underline flex items-center"
						>
							Sign Up
						</Link>
					</div>
					<button
						disabled={pending}
						type="submit"
						className="col-start-2 col-span-1 group relative flex items-stretch justify-center p-0.5 text-center font-medium transition-[color,background-color,border-color,text-decoration-color,fill,stroke,box-shadow] focus:z-10 focus:outline-none border border-transparent rounded-lg bg-sky-600 disabled:bg-sky-950 xs:w-2/3 mx-auto cursor-pointer hover:bg-sky-700"
						onMouseDown={() => {
							if ("serviceWorker" in navigator) {
								navigator.serviceWorker.ready.then((reg) => {
									reg.active?.postMessage({
										type: "action",
										action: "clearCache",
										// item: "",
									});
								});
							}
						}}
						onClick={() => {
							store.setState((state) => {
								return { ...state, ...initialStore };
							});
							// if (location) location.reload();
						}}
					>
						<span className="flex items-stretch transition-all duration-200 rounded-md px-4 py-2 text-sm text-slate-200 dark:text-slate-800">
							Sign In
						</span>
					</button>
				</form>
			</main>
		</div>
	);
}

export default LoginPage;
