import { Store } from "@tanstack/react-store";
import {
	Cribtrak,
	Cribtrak_Tool,
	Cribtrak_Transaction,
	Employee,
	Job,
	Kabtrak,
	Kabtrak_Tool,
	Kabtrak_Transaction,
	Kit,
	Portatrak,
	Portatrak_Transaction,
	Tool,
	User,
} from "./schema";

interface PortalStore {
	navOpen: boolean;
	cust_id: number;
	user: Employee | undefined;
	error?: {
		status?: number | boolean;
		error?: string;
		message?: string;
	};
	stats: {
		tools: Tool[] | never[];
		jobs: Job[] | never[];
		employees: Employee[] | never[];
		users: User[] | never[];
		fetch_complete: boolean;
		kabtrak: {
			kabtraks: Kabtrak[] | never[];
			kab_tools: Kabtrak_Tool[] | never[];
			kab_tools_out: Kabtrak_Tool[] | never[];
			users: User[] | never[];
			transactions: {
				queryString: string;
				querySettings: { [key: string]: string };
				transactions: Kabtrak_Transaction[] | never[];
			};
			fetch_complete: boolean;
		};
		cribtrak: {
			cribtraks: Cribtrak[] | never[];
			crib_tools: Cribtrak_Tool[] | never[];
			users: User[] | never[];
			transactions: {
				queryString: string;
				querySettings: { [key: string]: string };
				transactions: Cribtrak_Transaction[] | never[];
			};
			fetch_complete: boolean;
		};
		portatrak: {
			portatraks: Portatrak[] | never[];
			porta_kits: Kit[] | never[];
			users: User[] | never[];
			transactions: {
				queryString: string;
				querySettings: { [key: string]: string };
				transactions: Portatrak_Transaction[] | never[];
			};
			fetch_complete: boolean;
		};
	};
}

export const initialStore: PortalStore = {
	navOpen: true,
	cust_id: 0,
	user: undefined,
	error: undefined,
	stats: {
		tools: [],
		jobs: [],
		employees: [],
		users: [],
		fetch_complete: false,
		kabtrak: {
			kabtraks: [],
			kab_tools: [],
			kab_tools_out: [],
			users: [],
			transactions: {
				queryString: "",
				querySettings: {},
				transactions: [],
			},
			fetch_complete: false,
		},
		cribtrak: {
			cribtraks: [],
			crib_tools: [],
			users: [],
			transactions: {
				queryString: "",
				querySettings: {},
				transactions: [],
			},
			fetch_complete: false,
		},
		portatrak: {
			portatraks: [],
			porta_kits: [],
			users: [],
			transactions: {
				queryString: "",
				querySettings: {},
				transactions: [],
			},
			fetch_complete: false,
		},
	},
};

export const store = new Store<PortalStore>({ ...initialStore });
